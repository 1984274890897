var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-9"},[_c('ValidationObserver',{ref:"add_part"},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('h4',[_vm._v("Tên sách")])]),_c('v-col',{attrs:{"cols":"10"}},[_c('ValidationProvider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":'title',"value":_vm.part.title,"outlined":""},model:{value:(_vm.part.title),callback:function ($$v) {_vm.$set(_vm.part, "title", $$v)},expression:"part.title"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('h4',[_vm._v("Từ câu ")])]),_c('v-col',{attrs:{"cols":"10"}},[_c('ValidationProvider',{attrs:{"rules":"required|number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.part.from),expression:"part.from"}],staticClass:"number-input",attrs:{"type":"text"},domProps:{"value":(_vm.part.from)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.part, "from", $event.target.value)}}}),_c('div',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('h4',[_vm._v("Đến câu")])]),_c('v-col',{attrs:{"cols":"10"}},[_c('ValidationProvider',{attrs:{"rules":"required|number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.part.to),expression:"part.to"}],staticClass:"number-input",attrs:{"type":"text"},domProps:{"value":(_vm.part.to)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.part, "to", $event.target.value)}}}),_c('div',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"file"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{staticClass:"pl-1 upload-file-input",attrs:{"id":"fileUpload","prepend-icon":"mdi-soundcloud","label":"Upload File Audio"},on:{"change":_vm.onChangeFileUpload},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]),(_vm.audio_message)?_c('span',{staticClass:"validate-error text-danger text-danger",staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(_vm.audio_message)+" ")]):_vm._e()]}}])}),(_vm.part.audio)?_c('audio',{ref:"audio",staticClass:"audio-block",attrs:{"controls":"controls"}},[_c('source',{attrs:{"src":_vm.part.audio ? _vm.part.audio:'',"type":"audio/mpeg"}}),_vm._v(" Your browser does not support the audio element. ")]):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('h4',[_vm._v("Mô tả")])]),_c('v-col',{attrs:{"cols":"10"}},[_c('CkContent',{attrs:{"content":_vm.part.description},on:{"getData":function (value) { return _vm.part.description = value; }}})],1)],1)],1),_c('v-row',{staticClass:"d-flex align-center justify-center my-9"},[_c('v-col',{attrs:{"cols":"4"}},[_c('HnrButton',{attrs:{"color":"primary","text":_vm.save},on:{"hnrClickEvent":_vm.saveConfig}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }