<template>
  <div class="pa-9">
    <ValidationObserver ref="add_part">
      <v-row>
        <v-col cols="2">
          <h4>Tên sách</h4>
        </v-col>
        <v-col cols="10">
          <ValidationProvider
              name="title"
              rules="required"
              v-slot="{ errors }"
          >
            <v-text-field
                :name="'title'"
                v-model="part.title"
                :value="part.title"
                outlined
            >
            </v-text-field>
            <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <h4>Từ câu </h4>
        </v-col>
        <v-col cols="10">
          <ValidationProvider rules="required|number" v-slot="{ errors }">
            <input v-model="part.from" class="number-input" type="text">
            <div class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>
      <!--          to field-->
      <v-row>
        <v-col cols="2">
          <h4>Đến câu</h4>
        </v-col>
        <v-col cols="10">
          <ValidationProvider rules="required|number" v-slot="{ errors }">
            <input v-model="part.to" class="number-input" type="text">
            <div class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row
          >
        <v-col cols="12">
          <ValidationProvider
              name="file"
              v-slot="{ errors }"
          >
            <v-file-input
                class="pl-1 upload-file-input"
                id="fileUpload"
                prepend-icon="mdi-soundcloud"
                v-model="file"
                label="Upload File Audio"
                @change="onChangeFileUpload"
            ></v-file-input>
            <span class="validate-error text-danger text-danger">
                  {{ errors[0] }}
                </span>
            <span style="display: block" v-if="audio_message" class="validate-error text-danger text-danger">
                  {{ audio_message }}
                </span>
          </ValidationProvider>
          <audio class="audio-block" ref="audio" v-if="part.audio" controls="controls">
            <source :src="part.audio ? part.audio:'' " type="audio/mpeg">
            Your browser does not support the audio element.
          </audio>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <h4>Mô tả</h4>
        </v-col>
        <v-col cols="10">
          <CkContent
              :content="part.description"
              @getData="(value) => part.description = value"
          ></CkContent>
        </v-col>
      </v-row>
    </ValidationObserver>
    <v-row class="d-flex align-center justify-center my-9">
      <v-col cols="4">
        <HnrButton color="primary" @hnrClickEvent="saveConfig" :text="save"/>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {UPLOAD_FILE,GET_DETAIL_PART,UPDATE_PART} from "@/store/exams.module";
import {mapGetters} from "vuex";
import {EXAM_CONFIG_ID, EXAM_TYPE} from "@/common/constants";

export default {
  components: {
    CkContent: () => import( "@/components/ck-content"),
    HnrInput: () => import("@/components/elements/hnr-input"),
    HnrButton: () => import("@/components/elements/hnr-button")
  },
  data() {
    return {
      EXAM_CONFIG_ID: EXAM_CONFIG_ID,
      exam_config_id: this.$route.query.exam_config_id,
      part: {},
      imageError: "",
      image: {},
      label: "",
      placeholder: "Nhập số tiền khóa học",
      readonly: false,
      disabled: false,
      outlined: true,
      clearable: true,
      audio_message: "",
      save : 'Lưu',
      file: null,
      audio: {},
    }
  },
  computed: {
    ...mapGetters({
      detailPart: "detailPart",
    }),
    isListeningExam() {
      return (this.exam_config_id == EXAM_CONFIG_ID.toeic_listening);
    }
  },
  watch:{
    detailPart(newVal){
      this.part = Object.assign({},newVal)
    }
  },
  created() {
    this.$store.dispatch(GET_DETAIL_PART, this.$route.params.id);
    this.reloadAudio();
  },
  methods: {
    reloadAudio() {
      let vid = this.$refs.audio;
      if (vid) {
        vid.load();
      }
    },
    fileMp3Validation() {
      if (!this.isListeningExam) {
        return true;
      }
      var fileInput =
          document.getElementById('fileUpload');
      var filePath = fileInput.value;
      // Allowing file type
      var allowedExtensions =
          /(\.mp3)$/i;
      if (!allowedExtensions.exec(filePath)) {
        fileInput.value = '';
        return false;
      }
      return true;
    },
    onChangeFileUpload() {
      if (!this.fileMp3Validation()) {
        this.audio_message = "File tải lên không đúng định dạng mp3!";
        return null;
      }
      let formData = new FormData();
      formData.append("upload", this.file);
      this.$store.dispatch(UPLOAD_FILE, formData).then((data) => {
        if (data.status) {

          this.part.audio = data.data;
          this.reloadAudio();
        }
      });
    },
    async saveConfig(){
      let result = await this.$refs.add_part.validate()
      if (result) {
        this.$store
            .dispatch(UPDATE_PART, this.part)
            .then((data) => {
              if (data.data.status == 1) {
                this.$toasted.success('Update thành công', {
                  position: "top-right",
                  theme: "toasted-primary",
                  duration: 1000
                });
                this.$router.push('/exam/list/part');
                this.reloadAudio();
              }
            })
      }
      else {
        this.$toasted.error('Bạn chưa nhập đủ thông tin', {
          position: "top-right",
          theme: "toasted-primary",
          duration: 1000
        });
      }
    }
  }
}
</script>
<style lang="scss">

.number-input {
  width: 40px;
  height: 40px;
  border: 1px solid black;
  text-align: center;
  border-radius: 5px;
}

</style>
